@media ( min-width: 330px ){
    .react-player{
        max-width: 330px;
        max-height: 185px;
    }
}

@media ( min-width: 640px ){
    .react-player{
        max-width: 500px;
        max-height: 281px;
    }
}

@media ( min-width: 767px ){
    .react-player{
        max-width: 420px;
        max-height: 236px;
    }
}

@media ( min-width: 1024px ){
    .react-player{
        max-width: 500px;
        max-height: 281px;
    }
}

.react-player > video {
    border-radius: 10px;
}
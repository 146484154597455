.react-calendar__viewContainer button {
    border-radius: 100px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3px;
}

.react-calendar__navigation__prev2-button {
    border-top-left-radius: 8px;
}

.react-calendar__navigation__next2-button {
    border-top-right-radius: 8px;
}

.react-calendar__navigation {
    font-weight: bold;
    color: #FF4480;
}

.react-calendar__month-view__weekdays {
    text-transform: capitalize !important;
    color: #888888;
}

abbr:where([title]) {
    text-decoration: none !important;
}

.react-calendar__month-view__days__day--weekend {
    color: #333333;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #f1f1f1 !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #999999 !important;
}

.react-calendar__tile abbr {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}

.react-calendar__tile {
    overflow: visible !important;
}

.react-calendar__tile--now abbr{
    background: #FF4480 !important;
    color: white !important;
}

.react-calendar__navigation button:disabled {
    opacity: 60%;
}

.react-calendar__tile--now:enabled:hover abbr,
.react-calendar__tile--now:enabled:focus abbr {
    background: #FF4480 !important;
    opacity: 90%;
}

.react-calendar__tile--active abbr{
    background: #55AFF7 !important;
    color: white;
}

.react-calendar__tile--active:enabled:hover abbr,
.react-calendar__tile--active:enabled:focus abbr{
    background: #55AFF7 !important;
    opacity: 90%;
}

.react-calendar__tile--hasActive abbr {
    background: #76baff;
}

.react-calendar__tile:hover abbr {
    background-color: #e5e5e5;
}

.react-calendar__tile,
.react-calendar__tile:hover,
.react-calendar__tile--active,
.react-calendar__tile--hasActive,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus
 {
    background: transparent !important;
}

.abbr {
    text-decoration: none!important;
}

/* .react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
} */

.cell::after {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 8px;
    height: 8px; 
    border-radius: 50%; 
    background-color: #000;
  }
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    audio::-webkit-media-controls-play-button,
    audio::-webkit-media-controls-panel {
    @apply !bg-white
    }

    html, #root{
        @apply h-full scroll-smooth
    }

    body {
        @apply h-full font-quicksand text-base-text
    }

    .font-bold {font-weight: 600!important;}

    .left-login {
        @apply z-30 w-full md:w-1/2 md:bg-white max-md:h-full flex flex-col justify-center items-center md:py-3
    }

    .left-onboarding {
        @apply lg:bg-box w-full lg:w-[40%] pr-3 xs:pr-0 font-bold text-2xl lg:text-4xl relative
    }

    .right-login {
        @apply hidden md:flex w-1/2 bg-secondary max-md:h-full flex-col justify-center items-center px-10 lg:px-28
    }

    .right-onboarding {
        @apply w-full lg:w-[60%]
    }
    
    .text-login-right {
        @apply text-2xl font-bold text-center text-white
    }

    .toggle-password {
        @apply absolute right-3 h-[50px] bottom-0 flex items-center cursor-pointer
    }

    .input-area {
        @apply border font-normal text-base-text rounded-[10px] px-4 h-[50px] !leading-[50px] focus:outline-none focus:border-primary placeholder:font-normal placeholder:text-sm placeholder:text-[#C5C5C5]
    }

    .btn-outer-div {
        @apply w-[95%] xs-md:w-[300px] md:w-[330px] px-2 xs:px-0 mx-auto
    }

    .onboarding-outer-div {
        @apply w-[95%] xs-md:w-[320px] md:w-[350px] mx-auto mt-[30px] lg:mt-[45px] text-lg
    }

    .button {
        @apply font-bold text-center text-xl rounded-[10px] h-[50px] leading-[50px] transition-colors duration-300 block cursor-pointer w-full
    }

    .button-mt {
        @apply mt-4
    }

    .primary-button {
        @apply bg-primary text-white hover:bg-secondary focus:bg-secondary
    }

    .primary-button-outline {
        @apply bg-white border text-primary border-primary hover:border-secondary hover:text-secondary focus:border-secondary focus:text-secondary
    }

    .secondary-button {
        @apply bg-secondary text-white hover:bg-primary focus:bg-primary
    }

    .secondary-button-outline {
        @apply bg-white border text-secondary border-secondary hover:border-primary hover:text-primary focus:border-primary focus:text-primary
    }

    .inside-button {
        @apply text-xs leading-3 w-36 left-[calc(50%-72px)] h-[30px] absolute bottom-7 flex justify-center items-center gap-1
    }

    .text-button {
        @apply text-base leading-4 h-4 hover:text-secondary focus:text-secondary cursor-pointer
    }

    .onboarding-button {
        @apply w-full h-[50px] leading-[22px] relative overflow-hidden rounded-[10px] border border-onboarding-border mb-4 lg:mb-5 pl-[70px] pr-7 hover:bg-onboarding-bg-select focus:bg-onboarding-bg-select focus:text-base md:focus:text-lg
    }

    .onboarding-button-square {
        @apply text-xs h-[100px] w-[30%] xs:w-[100px] flex flex-col justify-center items-center rounded-[10px] border border-onboarding-border mb-4 lg:mb-5 hover:bg-onboarding-bg-select focus:bg-onboarding-bg-select focus:border-primary
    }

    .onboarding-button-left {
        @apply bg-onboarding-bg absolute inset-0 w-[60px] h-full flex justify-center items-center border-r border-onboarding-border
    }

    .onboarding-check {
        @apply hidden absolute inset-0 justify-end mr-[5px] md:mr-[20px]
    }

    .market-button-square {
        @apply text-xs h-[150px] w-[30%] xs:w-[100px] flex flex-col justify-center items-center rounded-[10px] border border-onboarding-border mb-4 lg:mb-5 hover:bg-onboarding-bg-select
    }

    .market-input {
        @apply input-area w-full border-onboarding-border pl-[76px] bg-transparent !h-[40px] !leading-[40px]
    }

    .market-input-left {
        @apply bg-nav-button-bg rounded-l-[10px] absolute inset-0 -z-10 w-[60px] flex justify-center items-center border-r border-onboarding-border  group-focus-within:border-primary
    }

    .market-input-outer {
        @apply mb-[15px] sm:mb-5 max-w-[493px] flex flex-col sm:!flex-row sm:justify-between sm:items-center mt-2
    }

    .market-info-input {
        @apply border border-onboarding-border font-normal text-base-text rounded-[10px] px-4 focus:outline-none focus:border-primary placeholder:font-normal placeholder:text-sm placeholder:text-placeholder w-full mb-[10px]
    }

    .navbar-btn {
        @apply md-lg:w-[24px] md-lg:h-[24px] w-[30px] h-[30px] bg-nav-button-bg border border-nav-button-border rounded-[10px] flex justify-center items-center
    }

    .top-navbar {
        @apply h-17 px-2 sm:px-5 md-lg:text-xs flex font-bold flex-row fixed top-0 left-0 right-0 border border-b-box justify-between bg-white z-20
    }

    .bottom-navbar {
        @apply h-17 px-6 sm:px-14 fixed md:hidden flex flex-row bottom-0 left-0 right-0 bg-white border border-t-box justify-between items-center z-20
    }

    .container {
        @apply max-w-7xl px-3 sm:px-8 md:px-10 lg:px-20 mx-auto flex flex-row pt-17
    }

    .main-content {
    @apply w-full md:!w-[70%] md:mr-7
    }

    .main-content-mobile {
        @apply w-full px-4
        }

    .side-bar {
        @apply hidden md:flex w-[30%] transparent-section h-fit mt-4 mb-5
    }

    .blue-header {
        @apply bg-primary text-base leading-5 xs:text-lg xs:leading-6 text-white font-bold rounded-[10px] h-32 py-2 max-xs:pt-1 text-end sm:text-center flex justify-end
    }

    .left-margin-bottom {
        @apply w-full h-24
    }

    .marketing-dialog {
        @apply bg-white border border-box rounded-[10px] w-full min-h-[140px] p-2 text-xs mb-5 relative
    }

    .blue-leftside {
        @apply absolute h-full left-0 top-0 bg-primary w-[10px] rounded-l-[10px]
    }

    .gray-rightside {
        @apply absolute h-full right-0 top-0 bg-nav-button-bg w-[10px] rounded-r-[10px]
    }

    .orange-leftside {
        @apply absolute h-full left-0 top-0 bg-secondary w-[10px] rounded-l-[10px]
    }

    .dropdown {
        @apply bg-white border border-placeholder relative max-xs:text-base text-lg font-normal max-w-lg mx-auto h-10 pr-5 sm:pr-0
    }

    .gray-section {
        @apply bg-box px-2 xs:px-[15px] py-[10px] rounded-[10px] text-center
    }

    .gray-section-top {
        @apply bg-box px-2 xs:px-[15px] py-[10px] rounded-t-[10px] text-center
    }

    .gray-section-bottom {
        @apply bg-box px-2 xs:px-[15px] py-[10px] rounded-b-[10px] text-center
    }

    .transparent-section {
        @apply bg-white border border-transparency-border px-2 xs:px-[15px] py-[10px] rounded-[10px] text-center
    }

    hr {
        @apply border-box my-4
    }

    .select-button {
        @apply bg-white border border-nav-button-border w-[100%] 2xs:min-w-[133px] xs-lg:w-[165px] h-[165px] lg:w-[190px] lg:h-[190px] rounded-[10px] flex justify-center items-center
    }

    .select-section {
        @apply w-[95%] max-xs:justify-between xs-lg:w-[350px] lg:w-[410px] mx-auto mt-[30px] md:mt-[45px] flex flex-row flex-wrap gap-[10px] sm:gap-[20px] text-center font-bold
    }

    .secondary-section {
        @apply mt-4 bg-secondary px-2 xs:px-[15px] py-[10px] rounded-[10px] text-center
    }

    h5 {
        @apply font-bold text-lg text-center
    }

    .word-button {
        @apply h-[25px] mx-1 my-2 bg-white border rounded-[10px] border-primary text-primary font-bold text-xs px-4 
    }

    .letter-note {
        @apply mx-auto w-[45px] h-[45px] bg-white border border-nav-button-bg rounded-[5px] flex justify-center items-center
    }

    .mobile-white {
        @apply md:hidden absolute w-[300%] 2xs:w-[260%] -left-[100%] 2xs:-left-[80%] h-[100%] rounded-[50%] bg-white z-20 top-[233px]
    }

    .mobile-shadow {
        @apply md:hidden absolute w-[300%] 2xs:w-[260%] -left-[100%] 2xs:-left-[80%] h-[100%] rounded-[50%] bg-[#C1C1C1] opacity-25 z-10 top-[230px] blur-sm
    }

    .dashboard-header {
        @apply bg-secondary text-base leading-5 xs:text-lg xs:leading-6 text-white font-bold rounded-[10px] h-32 py-2 max-xs:pt-1 text-end sm:text-center flex justify-end
    }

    @font-face {
        font-family: 'Material Symbols Outlined';
        font-style: normal;
        font-weight: 100 700;
        src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v61/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsI.woff2) format('woff2');
    }

    .material-symbols-outlined {
        font-family: 'Material Symbols Outlined';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
    }

    .time-select {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
        background-position: 100%;
        background-repeat: no-repeat; 
    }

    .play-button {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 10px solid #FF4480;
        border-bottom: 5px solid transparent;
        border-radius: 2px;
    }
    
    .inside-button:hover .play-button {
        border-left: 10px solid #55AFF7;
    }
    
    .inside-button:focus .play-button {
        border-left: 10px solid #55AFF7;
    }

    .teacher-detail-button {
        @apply button primary-button-outline max-w-[105px] h-[25px] text-xs leading-[25px] px-0 flex justify-center items-center
    }

    .teacher-card {
        @apply transparent-section mt-5 mx-2 text-xs text-start w-full xs:w-[330px] inline-flex
    }

    .accordion-div-main {
        @apply bg-white flex justify-between border border-box  group-focus-within:rounded-b-none rounded-[10px] px-4 py-[7px] items-center transition  duration-500 cursor-pointer pl-7 relative
    }    

    .accordion-div {
        @apply accordion-div-main group-focus:border-nav-button-bg group-focus:bg-box
    }

    .accordion-header {
        @apply items-center inline-flex justify-center transform transition  duration-500 group-focus:rotate-90 absolute left-0 ml-2 mr-auto h-full
    }

    .ticket-accordion-header {
        @apply transform transition duration-500 absolute left-0 ml-2 mr-auto
    }

    .ticket-accordion-div-main {
        @apply bg-white flex justify-between border border-box rounded-[10px] px-4 py-[7px] items-center transition  duration-500 cursor-pointer pl-7 relative
    }
    
    .ticket-accordion-content-main {
        @apply bg-white border-box rounded-[10px] rounded-t-none px-4 overflow-hidden duration-500
    }

    .ticket-accordion-text-main {
        @apply transition duration-500 ml-3
    }

    .accordion-text-main {
        @apply transition duration-500 ml-2 font-bold
    }

    .accordion-text {
        @apply accordion-text-main group-focus:text-secondary
    }

    .accordion-content-main {
        @apply group-focus-within:max-h-screen max-h-0 bg-white group-focus-within:border-x group-focus-within:border-b border-box rounded-[10px] rounded-t-none px-4 overflow-hidden  duration-500 group-focus-within:pt-2
    }

    .accordion-content {
        @apply accordion-content-main group-focus:bg-box group-focus:border-nav-button-bg overflow-y-scroll
    }

    .side-bar {
        @apply hidden md:flex w-[30%] h-fit transparent-section mt-4 mb-5
    }
    
    .h-line {
        @apply my-3 h-[2px] bg-primary before:absolute before:-top-1 before:left-0 before:w-[10px] before:h-[10px] before:bg-primary before:rotate-45 after:absolute after:-top-1 after:right-0 after:w-[10px] after:h-[10px] after:bg-primary after:rotate-45
    }

    .level-block {
        @apply w-[14%] mx-[6px] h-[50px]
    }

    .level-completed {
        @apply bg-[#7AD001]
    }

    .level-incompleted {
        @apply bg-incompleted
    }

    .active-level {
        @apply h-20 relative bg-[#AFDB73]
    }

    .active-level {
        @apply before:h-[28px] before:content-[attr(data-level)] before:bg-[#666666] before:text-white before:w-28 before:py-[2px] before:absolute before:-top-[44px] before:-left-[37px] before:z-10 after:bg-[#666666] after:h-[14px] after:w-[14px] after:absolute after:rotate-45 after:-top-6 after:left-[30%]
    }
    
    /* React Calendar Style */
    .react-calendar {
        @apply !text-base-text !font-quicksand !border-placeholder rounded-lg
    }

    .tooltip-bottom {
        @apply hidden group-hover:block absolute animate-fadeIn duration-300 text-center bg-[#222222] text-white text-sm px-2 py-1 rounded z-20 max-w-max -bottom-[36px] right-[-100px] left-[-100px] mx-auto before:absolute before:-top-1 before:right-0 before:left-0 before:mx-auto before:w-[10px] before:h-[10px] before:bg-[#222222] before:rotate-45
    }

    .tooltip-top {
        @apply hidden group-hover:block absolute animate-fadeIn duration-300 text-center bg-[#222222] text-white text-sm px-2 py-1 rounded z-20 max-w-max right-[-100px] left-[-100px] mx-auto -top-[51px] after:absolute after:-bottom-1 after:right-0 after:left-0 after:mx-auto after:w-[10px] after:h-[10px] after:bg-[#222222] after:rotate-45
    }

    .tooltip-top-content {
        @apply absolute animate-fadeIn text-center bg-[#222222] text-white text-sm px-2 py-1 rounded z-20 max-w-max right-[-100px] left-[-100px] mx-auto -top-[38px] after:absolute after:-bottom-1 after:right-0 after:left-0 after:mx-auto after:w-[10px] after:h-[10px] after:bg-[#222222] after:rotate-45
    }

    .group-active .tooltip-top {
        @apply block
    }

    #audioPlayer, #soundButton {
        @apply hidden
    }

    .message-box-assistant {
        @apply before:bg-box before:h-[14px] before:w-[14px] before:absolute before:rotate-45 before:top-[13px] before:-left-[7px] w-[90%] md:w-[70%]
    }

    .message-box-endpoint {
        /*@apply before:bg-box before:h-[14px] before:w-[14px] before:absolute before:rotate-45 before:top-[13px] before:-left-[7px]*/
    }

    .message-box-member {
        @apply after:bg-primary after:h-[14px] after:w-[14px] after:absolute after:rotate-45 after:top-[13px] after:-right-[7px] w-[90%] md:w-[70%]
    }

    .switch-circle {
        @apply absolute content-[""] h-5 w-5 right-[26px] top-[2px] bg-white rounded-full duration-500 peer-checked:translate-x-6
    }

    .pagination-button {
        @apply bg-white border border-[#ebedf2] px-4 focus:bg-primary focus:text-white font-normal text-[14px] outline-none rounded-full h-[45px] flex items-center justify-center
    }

    .search-button {
        @apply hover:opacity-90
    }

    .active-button {
        @apply font-bold bg-primary text-white hover:!bg-primary hover:!text-white focus:bg-primary focus:text-white
    }

    /* Scrollbar Styling */
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-track {
        background-color: #ebebeb;
    }

    ::-webkit-scrollbar-thumb {
        background: #6d6d6d; 
    }

    .package-select-button {
        @apply w-10 h-10 z-[11] rounded-full bg-box border border-[#C5C5C5] flex items-center relative justify-center
    }
    
    .package-select-button-active{
        @apply bg-secondary border-secondary text-white
    }
    
    .package-select-button-previous{
        @apply bg-[#FF8FB3] border-[#FF8FB3] text-white
    }
  
    .line-between {
        @apply w-[25vw] max-xs:max-w-[133px] max-w-[141px] lg:max-w-[144px] h-1 bg-[#C5C5C5] absolute
    }
  
    .line-between-active-before {
        @apply bg-gradient-to-r from-[#FF8FB3] to-secondary z-10
    }
  
    .line-between-active-after {
        @apply bg-gradient-to-r from-secondary to-[#C5C5C5] z-10
    }

    #word-modal-content ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    #word-modal-content ::-webkit-scrollbar-track {
        background-color: #ebebeb;
        -webkit-border-radius: 4px;
        border-radius: 4px;
    }

    #word-modal-content ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 4px;
        border-radius: 4px;
        background: #6d6d6d;
    }
  
    #conversational-ai-content ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    #conversational-ai-content ::-webkit-scrollbar-track {
        background-color: #ebebeb;
        -webkit-border-radius: 4px;
        border-radius: 4px;
    }

    #conversational-ai-content ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 4px;
        border-radius: 4px;
        background: #6d6d6d;
    }

    #table-scroll ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    #table-scroll ::-webkit-scrollbar-track {
        background-color: #ebebeb;
        -webkit-border-radius: 4px;
        border-radius: 4px;
    }

    #table-scroll ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 4px;
        border-radius: 4px;
        background: #6d6d6d;
    }
}
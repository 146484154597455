.bottom-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 10px 0 10px;
    border-color: #333333 transparent transparent transparent;
    border-radius: 2px;
}

/* used for card information dropdown */
